<template>
  <vue-perfect-scrollbar
    :settings="{ suppressScrollX: true, wheelPropagation: false }"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%"
  >
    <div class="pa-5 relative">
      <v-app-bar class="pt-1 grey lighten-5" fixed flat>
        <div class="d-flex justify-space-between align-center w-full">
          <v-text-field
            dense
            outlined
            :label="$t('drawer.searchPatient')"
            prepend-inner-icon="mdi-magnify"
            class="mr-8 mt-5"
            v-model="search"
            ref="search"
            @keydown.enter="getSearch"
          />
          <slot name="searchDrawerCloseButton" />
        </div>
      </v-app-bar>
    </div>

    <div class="px-5 mt-10">
      <div>
        <!-- <div class="heading-label pa-0 mt-10 mb-4">recent search</div> -->
        <!-- 2021.12.15 최근검색 기능 제외 -->

        <!-- 검색리스트 -->
        <div
          class="d-flex align-center mb-4"
          v-for="(item, i) in searchList"
          :key="i"
        >
          <div>
            <p class="ma-0">
              <a class="link-alt" @click="gotoPaitent(item)"
                >{{ item.firstName }} {{ item.MiddleName }}
                {{ item.lastName }}</a
              >
            </p>
            <p class="text--disabled text-caption ma-0">
              {{ item.Age }} {{ item.gender }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </vue-perfect-scrollbar>
</template>

<script>
// import { patientModel } from "@/api/models/patientModel";
// import { inquiryPatient } from "@/api/patient";

export default {
  props: {
    searchDrawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPath: "",
      // reqData: patientModel(),
      search: "",
      searchList: [],
    };
  },
  watch: {
    searchDrawer(value) {
      // console.log("", value);
      // if (value) {
      //   this.search = "";
      //   this.searchList = [];
      //   setTimeout(() => {
      //     this.$refs.search.focus();
      //   }, 100);
      // }
    },
  },
  mounted() {
    this.currentPath = window.location.pathname;
    // console.log("현재페이지", this.$route.query.page);
  },
  methods: {
    // async getSearch() {
    //   this.searchList = [];
    //   this.reqData.searchVal = this.search;
    //   const res = await inquiryPatient(this.reqData);
    //   this.searchList = res;
    // },
    // gotoPaitent(item) {
    //   this.$emit("closeDrawer");
    //   // 현재 로드된 환자페이지에서 에러방지(patientRead) 중복에러~
    //   if (this.currentPath === "/patientRead") {
    //     const currentPpk = this.$Base64.decode(this.$route.query.ppk);
    //     if (item.ppk === Number(currentPpk)) return;
    //   }
    //   const ppk = this.$Base64.encode(item.ppk);
    //   // reload 처리필요 -> eventBus로
    //   this.$eventBus.$emit("changePatient", ppk);
    //   this.$router.push({
    //     path: "/patientRead",
    //     query: { ppk },
    //   });
    // },
  },
};
</script>